<template>
  <div class="unload-content__header">
    <r-block
      no-padding
      col
    >
      <r-button
        type="success"
        :disabled="saveDisabled"
        :loading="loading"
        @click="$emit('save')"
      >
        Подтвердить разгрузку
      </r-button>
      <r-button
        simple
        :disabled="saveDisabled"
        @click="$emit('cancel')"
      >
        Сбросить изменения
      </r-button>
    </r-block>
    <r-button
      icon="add-plus"
      @click="$emit('open-create-vehicle')"
    >
      Создать новое ТС
    </r-button>
  </div>
</template>

<script>
import isEqual from 'lodash.isequal'

export default {
  props: {
    cargo: {
      type: Object,
      required: true
    },
    clone: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    activeItem() {
      return this.$store.state.unload.activeItem || null
    },
    saveDisabled() {
      return isEqual(this.cargo, this.clone)
    }
  }
}
</script>

<style lang="scss">
.unload-content__header {
  display: grid;
  padding: 0.5rem;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  justify-content: space-between;
  border-bottom: 1px solid var(--dividers_low_contrast)
}
</style>
